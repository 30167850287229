import Button from "components/common/buttons/buttons";
import TopHeader from "components/common/header";
import { Check } from "components/common/Icons";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { TYPO } from "styles/Typo";
import * as Sentry from "@sentry/nextjs";
import { useSession } from "next-auth/react";
import { useIosFixedBottomButton } from "utils/ios";

function KBServerError({
  errorContents,
  errorId,
}: {
  errorContents: any;
  errorId: string;
}) {
  const router = useRouter();
  useIosFixedBottomButton();

  const [checked, setChecked] = useState(true);
  const isTokenError =
    errorContents?.dataHeader?.processMessage?.includes("토큰");

  const id = isTokenError ? "KA02" : errorId;
  const { data: session }: any = useSession();
  useEffect(() => {
    // sentry에 로그 전송
    Sentry.captureMessage(`[KB] ${id} ERROR / ${session?.user?.email}`, {
      level: "error",
      tags: {
        errorType: `[KB] ${id}`,
      },
      extra: { data: errorContents },
    });
  }, []);

  const handleGoBack = async () => {
    if (checked) {
      // 개발팀 연락
      try {
        await fetch("/api/kb/report", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            errorId: id,
            value: errorContents,
            email: session?.user?.email,
          }),
        });
      } catch (e) {
        console.log(e);
        Sentry.captureMessage(
          `[KB] ${id} Report ERROR / ${session?.user?.email}`
        );
      }
    }
    router.push("/");
  };

  return (
    <main
      className={`z-[40] fixed top-0 min-w-[360px] max-w-[800px] w-screen bg-white min-h-screen`}
    >
      <div
        className="w-full  flex flex-col"
        // style={{
        //   height: "calc(100dvh - 140px)",
        // }}
      >
        <TopHeader
          clickHandler={() => {
            router.push("/");
          }}
        />
        <div className="px-6 pt-6">
          <span className={`${TYPO.TYPO3_SB} text-gray90`}>
            오류가 발생했어요 <br /> 내용을 고객센터에 접수할게요
          </span>
        </div>
        <div
          // className="grow flex items-center justify-center"
          className="absolute left-[50%] top-[50%] transform translate-x-[-50%] translate-y-[-50%]"
        >
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="160"
              height="120"
              viewBox="0 0 160 120"
              fill="none"
            >
              <circle cx="45" cy="41" r="32" fill="#BDC7D1" />
              <path
                d="M39.9125 27.6726C39.5883 24.6467 41.9596 22.0078 45.0028 22.0078C48.046 22.0078 50.4173 24.6467 50.0931 27.6726L48.1549 45.7625C47.9823 47.3734 46.6229 48.5949 45.0028 48.5949C43.3827 48.5949 42.0233 47.3734 41.8507 45.7625L39.9125 27.6726Z"
                fill="white"
              />
              <path
                d="M41.2047 56.1913C41.2047 54.0936 42.9051 52.3931 45.0028 52.3931C47.1005 52.3931 48.801 54.0936 48.801 56.1913C48.801 58.2889 47.1005 59.9894 45.0028 59.9894C42.9051 59.9894 41.2047 58.2889 41.2047 56.1913Z"
                fill="white"
              />
              <path
                d="M40.9281 93.6016C38.0481 79.2016 54.9281 57.6016 63.7281 48.6016L93.1273 50.4016H93.7262L123.125 48.6016C131.925 57.6016 148.805 79.2016 145.925 93.6016C143.045 108.002 127.526 110.802 120.126 110.402L112.326 93.6016L109.6 97.4016H77.5273L74.5273 93.6016L66.7273 110.402C59.3276 110.802 43.8081 108.002 40.9281 93.6016Z"
                fill="#5268E9"
              />
              <path
                fill-rule="evenodd"
                clipRule="evenodd"
                d="M112.297 93.3984C107.515 96.6662 98.8012 102.689 96.1408 105.349C93.2608 108.229 95.3408 109.749 96.7408 110.149H115.341L119.262 108.406L112.297 93.3984Z"
                fill="#FFCBA7"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M74.6015 93.3984C79.3832 96.6662 88.0972 102.689 90.7576 105.349C93.6376 108.229 91.5576 109.749 90.1576 110.149H71.5576L67.6366 108.406L74.6015 93.3984Z"
                fill="#FFCBA7"
              />
              <circle cx="66.3938" cy="46.8" r="7.8" fill="#FFCBA7" />
              <circle cx="119.198" cy="46.8" r="7.8" fill="#FFCBA7" />
              <circle cx="93.4031" cy="40.2" r="31.2" fill="#262626" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M67.1016 56.9691C71.1444 55.5448 79.9821 51.8122 81.4113 48.0008C85.2113 49.6008 93.4113 52.6808 95.8113 52.2008L99.4113 46.8008C103.011 49.8008 112.011 55.6808 119.211 55.2008L121.052 54.6987C115.832 64.6292 105.416 71.4016 93.4176 71.4016C82.358 71.4016 72.6424 65.6472 67.1016 56.9691Z"
                fill="#FFCBA7"
              />
              <ellipse
                cx="87.7"
                cy="63.6008"
                rx="2.7"
                ry="1.8"
                fill="#262626"
              />
              <ellipse
                cx="102.098"
                cy="63.6008"
                rx="2.7"
                ry="1.8"
                fill="#262626"
              />
            </svg>
          </div>
        </div>
      </div>

      <div
        className={` z-[99] bg-white w-full px-5 pt-4 pb-[36px] fixed bottom-0 left-0 !font-semibold flex flex-col gap-4 `}
      >
        <div
          className="flex gap-2 items-center py-2 cursor-pointer"
          onClick={() => {
            setChecked((prev) => !prev);
          }}
        >
          <Check fill={checked ? "blue40" : "gray30"} width={20} height={20} />
          <span className={`${TYPO.TYPO7_MD} text-gray50`}>
            빠른 해결을 위해 개발팀의 연락을 받을게요
          </span>
        </div>
        <Button
          type="primary"
          size="lg"
          clickHandler={() => {
            handleGoBack();
          }}
          text={"홈으로 돌아가기"}
        />
      </div>
    </main>
  );
}

export default KBServerError;
