import Button from "components/common/buttons/buttons";
import TopHeader from "components/common/header";
import { useRouter } from "next/router";
import { useState } from "react";
import { TYPO } from "styles/Typo";
import TermsAgreePage from "./TermsAgree";
import { useIosFixedBottomButton } from "utils/ios";

function TermsAgreeExpired({
  setModalOpen,
  setKbAuth,
}: {
  setModalOpen: any;
  setKbAuth?: any;
}) {
  useIosFixedBottomButton();

  const [agreePage, setAgreePage] = useState(false);
  const router = useRouter();
  if (agreePage) {
    return (
      <div
        className={`absolute top-0 left-0 z-[40] min-w-[360px] max-w-[800px] w-screen bg-white min-h-screen`}
      >
        <TermsAgreePage
          isMobile={true}
          completeHandler={() => {
            setModalOpen();
          }}
        />
      </div>
    );
  }

  return (
    <main
      className={`fixed top-0 left-0 z-[40] min-w-[360px] max-w-[800px] w-screen bg-white min-h-screen`}
    >
      <TopHeader
        clickHandler={() => {
          router.back();
        }}
      />{" "}
      <div className="px-6 pt-6 mb-6">
        <span className={`${TYPO.TYPO3_SB} text-gray90`}>
          서비스 가입 1년이 지나
          <br />
          재동의가 필요한 약관이 있어요
        </span>
      </div>
      <div
        className="absolute left-[50%] top-[50%] transform translate-x-[-50%] translate-y-[-50%]"
        // className="flex items-center justify-center"
        // style={{ height: "calc(100dvh - 210px)" }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="160"
          height="160"
          viewBox="0 0 160 160"
          fill="none"
        >
          <path
            d="M37.0909 41.0909H21.2727C18.3607 41.0909 16 43.4516 16 46.3636V109.636C16 115.46 20.7214 120.182 26.5455 120.182C32.3695 120.182 37.0909 115.46 37.0909 109.636V41.0909Z"
            fill="#647A90"
          />
          <path
            d="M47.6364 20C41.8123 20 37.0909 24.7214 37.0909 30.5455V109.636C37.0909 115.46 32.3695 120.182 26.5455 120.182H121.455C127.279 120.182 132 115.46 132 109.636V30.5455C132 24.7214 127.279 20 121.455 20H47.6364Z"
            fill="#EFF2F5"
          />
          <path
            d="M52.9091 41.0909C52.9091 38.1789 55.2698 35.8182 58.1818 35.8182H110.909C113.821 35.8182 116.182 38.1789 116.182 41.0909V46.3636C116.182 49.2757 113.821 51.6364 110.909 51.6364H58.1818C55.2698 51.6364 52.9091 49.2757 52.9091 46.3636V41.0909Z"
            fill="#BDC7D1"
          />
          <path
            d="M52.9091 64.8182C52.9091 63.3622 54.0894 62.1818 55.5455 62.1818H113.545C115.001 62.1818 116.182 63.3622 116.182 64.8182C116.182 66.2742 115.001 67.4545 113.545 67.4545H55.5455C54.0894 67.4545 52.9091 66.2742 52.9091 64.8182Z"
            fill="#BDC7D1"
          />
          <path
            d="M55.5455 75.3636C54.0894 75.3636 52.9091 76.544 52.9091 78C52.9091 79.456 54.0894 80.6364 55.5455 80.6364H113.545C115.001 80.6364 116.182 79.456 116.182 78C116.182 76.544 115.001 75.3636 113.545 75.3636H55.5455Z"
            fill="#BDC7D1"
          />
          <path
            d="M52.9091 91.1818C52.9091 89.7258 54.0894 88.5454 55.5455 88.5454H113.545C115.001 88.5454 116.182 89.7258 116.182 91.1818C116.182 92.6378 115.001 93.8182 113.545 93.8182H55.5455C54.0894 93.8182 52.9091 92.6378 52.9091 91.1818Z"
            fill="#BDC7D1"
          />
          <path
            d="M55.5455 101.727C54.0894 101.727 52.9091 102.908 52.9091 104.364C52.9091 105.82 54.0894 107 55.5455 107H113.545C115.001 107 116.182 105.82 116.182 104.364C116.182 102.908 115.001 101.727 113.545 101.727H55.5455Z"
            fill="#BDC7D1"
          />
          <path
            d="M144 112C144 127.464 131.464 140 116 140C100.536 140 88 127.464 88 112C88 96.536 100.536 84 116 84C131.464 84 144 96.536 144 112Z"
            fill="#F99F01"
          />
          <path
            d="M111.547 100.339C111.263 97.6917 113.338 95.3827 116.001 95.3827C118.664 95.3827 120.738 97.6917 120.455 100.339L118.759 116.168C118.608 117.578 117.418 118.646 116.001 118.646C114.583 118.646 113.394 117.578 113.243 116.168L111.547 100.339Z"
            fill="white"
          />
          <path
            d="M112.677 125.293C112.677 123.458 114.165 121.97 116.001 121.97C117.836 121.97 119.324 123.458 119.324 125.293C119.324 127.129 117.836 128.617 116.001 128.617C114.165 128.617 112.677 127.129 112.677 125.293Z"
            fill="white"
          />
        </svg>
      </div>
      <div
        className={` z-[99] bg-white w-full px-5 pt-4 pb-[30px] fixed bottom-0 left-0 !font-semibold flex flex-col gap-4 `}
      >
        <Button
          type="primary"
          size="lg"
          clickHandler={() => {
            setAgreePage(true);
          }}
          text={"동의하고 서비스 계속 이용하기"}
        />
      </div>
    </main>
  );
}

export default TermsAgreeExpired;
